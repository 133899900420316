import React, { useEffect, useRef, useState, KeyboardEvent } from 'react'
import useMediaQuery from './useMediaQuery'
import { Link, redirect, useNavigate } from "react-router-dom"
import { navs, Nav } from "../pages/data"
import icon1 from '../assets/images/icon_01.png'
import icon2 from '../assets/images/icon_02.png'
import logo from '../assets/images/logo3.png'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface NavProp {
  nav: Nav
}

const sub = (subs: Nav[]) => {
  return <div className="sub-menu">
    {subs.filter(it=>!it.hide).map((it, index) => {
      if(it.type === 'inner') {
        return <Link key={index} to={it.url || '#'}><div>{it.title}</div></Link>
      } else {
        return <a  key={index} target="_blank" href={it.url || '#'}><div>{it.title}</div></a>
      }
    })}
  </div>
}

const NavItem: React.FC<NavProp> = ({ nav }) => {
  const out = (title: string, icon: string, url: string) => {
    return (<a href={url} target='_blank'>
      <i className={icon}></i>{title}
    </a>)
  }

  const inner = (title: string, icon: string, url: string) => {
    return (<Link to={url}>
      <i className={icon}></i>{title}
    </Link>)
  }
  return (<>
    {nav.type === 'inner' ? inner(nav.title, nav.icons || '', nav.url) : out(nav.title, nav.icons || '', nav.url)}
  </>)
}

const Header: React.FC = () => {
  const [currNav, setCurrNav] = useState(0)
  const navRef = useRef<any | undefined>()
  const matchs = useMediaQuery('screen and (min-width: 320px) and (max-width: 767px)')
  const [query, setQuery] = useState('')
  const navigate = useNavigate()

  const opts = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }

  const setHome = () => {
    toast.dismiss()
    toast('抱歉，您所使用的浏览器不支持此此操作，请手动将本网站添加为首页', {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  const setFavor = () => {
    toast.dismiss()
    toast('抱歉，您所使用的浏览器不支持此此操作，请按 【Ctrl + D】键收藏本站', {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  const showMenu = () => {
    const navs = document.querySelectorAll('.hd-nav')
    if (navRef.current) {
      const tmp = navRef.current as HTMLDivElement;
      const display = tmp.style.display;
      if (display === 'none') {
        tmp.style.display = 'block';
      } else {
        tmp.style.display = 'none';
      }

      console.log('navs:', display, navs, navRef)
    }
  }

  const handleSearch = () => {
    console.log('query', query)
    const tmp = encodeURIComponent(query)
    navigate(`/search/${tmp}`)
  }

  const goSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if(e.code === 'Enter') {
      handleSearch()
    }
  }

  const dateStr = (new Date()).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

  return (
    <div className="head">
      <div className="auto">
        <div className="hd-t clearfix">
          <div className="lbox fl">
            <Link to="#" onClick={setHome} className="lk">
              <img src={icon1} className="ico" alt="" />设为首页
            </Link>
            <span className="line"></span>
            <Link onClick={setFavor} to="#" className="lk">
              <img src={icon2} className="ico" alt="" />加入收藏
            </Link>
          </div>
          <div className="rbox fr">
            <span className="date">{dateStr}</span>
          </div>
        </div>
        <div className="hd-c clearfix">
          <Link to='/' className="hd-logo fl">
            <img src={logo} className="img" alt="" style={{ height: 100 }} />
          </Link>
          <div className="hd-sear fr clearfix">
            <input value={query} onKeyDown={goSearch} onChange={(ev) => setQuery(ev.target.value)} type="text" className="ipt ipt-txt" placeholder="请输入您要搜索的关键词" />
            <button onClick={handleSearch} className="submit" type="button"></button>
          </div>
        </div>
        <div className="hd-nav" ref={navRef}>
          <ul>
            {navs.filter(it=>!it.hide).map((it, index) => {
              return <li key={index} onClick={() => setCurrNav(index)} className={currNav === index ? 'cur' : ''}>
                <NavItem nav={it} />
                {sub(it.subs || [])}
              </li>
            })}
          </ul>
        </div>
      </div>
      <span className="sj-menu" onClick={() => { showMenu() }}></span>
      <ToastContainer />
    </div>
  )
}

export default Header
