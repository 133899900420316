import React from 'react'
import {ReactComponent as LoadIcon} from '../assets/images/loading.svg'

interface Props {
  children?: React.ReactNode
}

const Loading: React.FC<Props> = ({ children }) => {
  return (
    <div style={{textAlign: 'center', color: '#d81e06'}}>
        <LoadIcon style={{width: 100, height: 100}} />
      <div>加载中...</div>
    </div>
  )
}

export default Loading
