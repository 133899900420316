
import React, { useState, useEffect } from 'react'
import { homeCat1, Category } from '../pages/data';
import { listByCode, flashList } from '../api/api';

import noImage from '../assets/images/no_image.png'
import Articles from './common';
import { Article as ArticleModel, Flash as FlashModel } from '../pages/data';
import { Link } from 'react-router-dom';

interface PanelProps {
  leftList?: FlashModel[]
  rightList?: ArticleModel[]
  changeRight?(code: string): void
}

interface Props {
  children?: React.ReactNode
  leftList?: FlashModel[]
  rightList?: ArticleModel[]
  changeRight?(code: string): void
}

const parseDate = (date: string | undefined) => {
  if (date) {
    const [_date,] = date?.split(' ')
    const [y, m, d] = _date?.split('-')
    return [`${y}.${m}`, d]
  } else {
    return ['', '']
  }
}

// 左侧面板
const LeftPanel: React.FC<PanelProps> = ({ leftList }) => {
  const [currIndex, setCurrIndex] = useState(0)
  const len = leftList?.length || 1
  const first = leftList ? leftList[currIndex] : null

  const calIdx = (idx: number) => {
    if (idx < 0) {
      return len - 1
    } else {
      return idx % len
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrIndex((currIndex + 1) % (leftList?.length ?? 0));
    }, 5000);
    return () => clearInterval(interval);
  }, [currIndex, leftList])


  return <div className="idx-slide fl">
    <div className="view-list">
      <div className="flow-left flow-btn"
        onClick={() => setCurrIndex(calIdx(currIndex - 1))}></div>
      <div className="flow-right flow-btn"
        onClick={() => setCurrIndex(calIdx(currIndex + 1))}></div>
      <div className="item">
        <a href={first?.outLink ?? '#'} className="imgbox">
          <img src={`https://api.nxgqt.org${first?.imageUrl ?? noImage}`}
               className="img" alt="" />
          <h6 className="title ellipsis">{first?.title}</h6>
        </a>
      </div>
    </div>
    <div className="thumb-list">
      <div>
        {leftList?.map((it, index) => {
          return <div className='item' key={index}
            style={{ width: `${1.0 / leftList.length * 100}%` }}>
            <img className={`smimg ${index === currIndex ? 'flow-border' : ''}`}
              onClick={() => setCurrIndex(index)}
              src={`https://api.nxgqt.org${it.imageUrl}`} alt="" />
          </div>
        })}
      </div>
    </div>
  </div>
}

// 右侧面板
const RightPanel: React.FC<PanelProps> = ({ changeRight, rightList }) => {
  const [idx1, setIdx1] = useState(1)
  const [cat, setCat] = useState<Category>()
  const first = rightList ? rightList[0] : null
  const others = rightList?.filter((_, index) => index !== 0)

  const dateSeq = parseDate(first?.createdAt)

  const handleChange = (index: number, code: string) => {
    setIdx1(index)
    setCat(homeCat1.find(it => it.code === code))
    changeRight?.(code)
  }

  useEffect(() => {
    handleChange(idx1, 'hot')
  }, [])

  return (
    <div className="idx-tout fr">
      <div className="idx-menu tabmenu">
        <div className="box">
          {homeCat1.map((it, index) => {
            return <span key={index}
              onMouseOver={() => handleChange(index, it.code)}
              onClick={() => handleChange(index, it.code)}
              className={`lk ${index === idx1 ? 'cur' : ''}`}>
              {it.name}
            </span>
          })}
        </div>
      </div>
      <div className="tabwrap">
        <div className="module">
          <div className="idx-mod">
            <div className="hot-box clearfix">
              <div className="date">
                <p className="day">{dateSeq[1]}</p>
                <p className="year">{dateSeq[0]}</p>
              </div>
              <div className="info">
                <h5>
                  <Link to={`/detail/${first?.id}`}
                    className="title ellipsis">{first?.title}</Link>
                </h5>
                <div className="txt ellipsis">{first?.intro}</div>
              </div>
            </div>
            <Articles articles={others} />
            <div className="clearfix"></div>
            <div className="more">
              <Link to={`/article/${cat?.code}?name=${cat?.name}`}>
                更多 &gt;&gt;
              </Link>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>)
}

const HomeBlock1: React.FC<Props> = () => {
  const [topLeft, setTopLeft] = useState([])
  const [topRight, setTopRight] = useState([])

  const handleChangeTopRight = (code: string) => {
    listByCode(code, 9).then(res => {
      setTopRight(res.records)
    })
  }

  useEffect(() => {
    flashList('banner').then(res => {
      setTopLeft(res)
    })
    handleChangeTopRight('hot')
  }, [])

  return (<>
    <div className="idx-s1 m clearfix">
      <LeftPanel leftList={topLeft} />
      <RightPanel changeRight={handleChangeTopRight} rightList={topRight} />
    </div>
  </>)
}

export default HomeBlock1
