import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { search, listByCode } from '../api/api'
import { PageModel, getPageNums } from './articles'

import { Article, allCats } from './data'

interface Props {
  children?: React.ReactNode
  code?: string
  title?: string | null
}

const NoCatList: React.FC<Props> = ({ code, title }) => {
  const { query, catCode } = useParams()
  const [searchParams,] = useSearchParams();

  if (catCode) {
    code = catCode
  }

  if (searchParams.has('name')) {
    title = searchParams.get('name')
  }

  console.log('cat code:', catCode)

  const [list, setList] = useState<any[]>([])

  const [page, setPage] = useState<PageModel>()
  const [nums, setNums] = useState<number[]>([])
  const [p, setP] = useState<number>(1)

  const handlePage = (cp: number) => {
    setP(cp)
    loadData(cp)
  }

  const handleNext = (cp: number) => {
    if (page) {
      if (cp >= page?.pages) {
        handlePage(page?.pages)
      } else {
        handlePage(cp + 1)
      }
    }
  }

  const handlePrev = (cp: number) => {
    if (cp > 1) {
      handlePage(cp - 1)
    } else {
      handlePage(1)
    }
  }

  const dynLink = (article: Article) => {
    if (article.isLink === 1) {
      return <a target="_blank" className="title ellipsis" href={article.linkUrl}>{article.title}</a>
    }

    return <Link to={`/detail/${article.id}`} className="title ellipsis">{article.title}</Link>
  }

  const loadData = (p: number) => {
    if (code === 'query') {
      if (query) {
        search(query, p).then(res => {
          const data = res as PageModel
          setPage(data)
          setList(data.records)
          setNums(getPageNums(data.current, data.pages))
        })
      }
    } else {
      if (code) {
        listByCode(code, 20, p).then(res => {
          const data = res as PageModel
          setPage(data)
          setList(data.records)
          setNums(getPageNums(data.current, data.pages))
        })
      }
    }
  }
  useEffect(() => {
    window.scrollTo({ top: 0 })
    loadData(p)
  }, [query, code])
  return (
    <div className="main auto">
      <div className="top-bar clearfix">
        <span className="bt">{title}</span>
        <div className="crumb fr">
          当前位置: &nbsp;
          <Link to="/">首页 </Link> &gt;
          <span> {title}</span>
        </div>
      </div>
      <div className="wrap clearfix">
        <div className="wrap-r" style={{ marginLeft: 0 }}>
          <ul className="xw-list">
            {list.map(it => {
              return <li key={it.id} className="item">
                {dynLink(it)}
                <span className="date">{it.createdAt}</span>
              </li>
            })}
          </ul>
          <div className="pagelist">
            <span className="total">共{page?.total}条</span>
            <div className="pages">
              <div className="first" onClick={() => handlePage(1)}>
                首页
              </div>
              <div className="prv" onClick={() => handlePrev(p)}>
                上一页
              </div>
              <ul className="pagingUl">
                {nums.map(it => {
                  return <li key={it}>
                    <Link onClick={() => handlePage(it + 1)} to='#' className={`${it + 1 === p ? 'active' : ''}`}>{it + 1}</Link>
                  </li>
                })}
              </ul>
              <div className="next" onClick={() => handleNext(p)}>下一页</div>
              <div className="last" onClick={() => handlePage(page ? page?.pages : 1)}>末页</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoCatList
