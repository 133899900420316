import React, { useEffect, useState } from 'react'
import { Article } from '../pages/data'
import Articles from './common'
import { listByCode, topCatArticle } from '../api/api'
import { Link } from 'react-router-dom'
import ZImage from './ZImage'

interface Props {
  children?: React.ReactNode
}

interface PanelProps {
  klass: string
  label: string
  code: string
  articles?: Article[]
  top: Article[]
}

const parseDate = (date: string | undefined) => {
  if (date) {
    const [_date, _] = date?.split(' ')
    const [y, m, d] = _date?.split('-')
    return [`${y}.${m}`, d]
  } else {
    return ['', '']
  }
}

const SubPanel: React.FC<PanelProps> = ({ top, code, klass, label, articles }) => {

  const others = articles?.filter((_, index) => index !== 0)

  const intro = (intro: string | null) => {
    if (intro) {
      if (intro.length > 30) {
        return intro.substring(0, 28) + '...'
      }

      return intro
    }
    return ''
  }

  return (
    <div className={`col ${klass}`}>
      <div className="idx-tt">
        <span className="bt">{label}</span>
      </div>
      <div className="idx-mod">
        <div className="hot-box " style={{ display: 'flex', justifyContent: 'space-between' }}>
          {top.map(it => {
            return <Link to={`/detail/${it.id}`} className="imgbox">
              <ZImage href={it.coverImage} />
              <h5><Link to={`/detail/${it.id}`} className="title ellipsis">{it.title}</Link></h5>
            </Link>
          })}
        </div>
        <Articles articles={others} />

        <div className="more mg-b-10">
          <Link to={`/article/${code}?name=${label}`}>
            更多 &gt;&gt;
          </Link>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
  )
}

const HomeBlock3: React.FC<Props> = ({ children }) => {
  const [topFocus, setTopFocus] = useState<Article[]>([])
  const [topStudy, setTopStudy] = useState<Article[]>([])
  const [focus, setFocus] = useState<Article[]>([])
  const [study, setStudy] = useState<Article[]>([])

  useEffect(() => {
    topCatArticle('media').then(res => {
      setTopFocus(res)
    })

    listByCode('media').then(res => {
      setFocus(res.records)
    })

    topCatArticle('study').then(res => {
      setTopStudy(res)
    })

    listByCode('study').then(res => {
      setStudy(res.records)
    })
  }, [])

  return (
    <div className="idx-s4 m clearfix">
      <SubPanel code='media' label='媒体聚焦' klass='fl' top={topFocus} articles={focus} />
      <SubPanel code='study' label='青年大学习' klass='fr' top={topStudy} articles={study} />
    </div>
  )
}

export default HomeBlock3
