/* 专题组件 */

import { useEffect, useState } from "react";
import { getTopics, getSpecial } from '../api/api'
export interface TopicModel {
  image?: string
  linkUrl?: string
  name?: string
  color?: string
  id?: string
  code?: string
  backgroundImage?: string
  status?: string
  klass?: string
  type?: string
  isShow?: number
}

export interface PageResult {
  records: TopicModel[]
}

interface Props {
}

const checkStyle = (type: string|undefined) => {
  if(type === 'big') return 'lk1'
  if(type === 'half') return 'lk2'
  if(type === 'third') return 'lk3'
}

const Topic: React.FC<Props> = () => {

  const [topics, setTopics] = useState<TopicModel[]>([])

  useEffect(() => {
    getSpecial().then(res => {
      let data = res as TopicModel[]
      setTopics(data.filter(it => it.isShow === 1))
    })

  }, [])
  return (
    <div>
      <div className="idx-ad">
        {topics?.map((it, index) => (
          <a key={index} href={it.linkUrl}
            target="__blank"
            className={`lk ${checkStyle(it.type)}`}>
            <img style={{ width: '100%' }}
              src={`https://api.nxgqt.org${it.backgroundImage}`}
              className="img" alt="" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Topic;
