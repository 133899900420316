import React from 'react'
import { Link } from 'react-router-dom'
import { Article } from '../pages/data'

import noImage from '../assets/images/no_image1.png'

interface Props {
  children?: React.ReactNode
  articles: Article[] | undefined
}

const Articles: React.FC<Props> = ({ articles, children }) => {
  const dateOnly = (val:string) => {
    return val.split(' ')[0]
  }

  // 判断是否为外部链接
  const dynLink = (article: Article) => {
    if (article.isLink === 1) {
      return <a target="_blank" className="title ellipsis" href={article.linkUrl}>{article.title}</a>
    }

    return <Link to={`/detail/${article.id}`} className="title ellipsis">{article.title}</Link>
  }

  return (
    articles && articles?.length > 0 ? (
      <ul className="list">
        {articles?.map((it, index) => {
          return <li className="item" key={index}>
            {dynLink(it)}
            <span className="date">{dateOnly(it?.createdAt ?? '')}</span>
          </li>
        })}
      </ul>
    ) : (
      <div className="no-list-data">
        <div>
          <img src={noImage} style={{ width: 300 }} alt="no find" />
        </div>
        <div>
          <span>暂无数据</span>
        </div>
      </div>
    )
  )
}

export default Articles
