import topImg1 from '../assets/images/idx_02.jpg'
import topImg2 from '../assets/images/idx_03.jpg'
import topImg3 from '../assets/images/idx_04.jpg'
import wechatImg1 from '../assets/images/nxlogo/qr1.jpeg'
import wechatImg2 from '../assets/images/nxlogo/qr2.jpeg'
import wechatImg3 from '../assets/images/nxlogo/qr_app.jpeg'
import Marquee from '../components/marquee'
import { TopicModel } from '../components/topic'

export interface Category {
  id?: string
  code: string
  name: string
  image?: string
  url?: string
  type?: string
}

export interface FriendLink {
  id?: string
  siteName?: string
  siteLink?: string
  linkType?: string
}

export interface Article {
  id?: string
  author?: string
  code?: string
  categoryId?: string
  createdAt?: string
  title: string
  content?: string
  visits?: number
  source?: string
  coverImage?: string
  intro?: string
  isLink?: number
  linkUrl?: string
  category?: Category
}

export interface Flash {
  id?: string
  code?: string
  title?: string
  orgCode?: string
  flashGroupId?: string
  createdAt?: string
  imageUrl?: string
  outLink?: string
  optnType?: string
  status?: string
}

export interface Marquee {
  id?: string
  image: string,
  url: string,
}

export interface Wechat {
  id?: string,
  image: string,
  title?: string,
}

export interface Nav {
  title: string
  icons?: string
  url: string
  type: string
  code?: string
  pcode?: string
  hide?: boolean
  subs?: Nav[]
}

export const navs: Nav[] = [
  {
    title: '首页',
    icons: 'ico ico1',
    url: '/',
    type: 'inner',
    code: 'home',
  },
  {
    title: '关于我们',
    icons: 'ico ico2',
    url: '/orgs',
    type: 'inner',
    code: 'about',
    subs: [
      {
        title: '领导班子',
        type: 'inner',
        code: 't1',
        url: '/org-detail/1585915645304954881',
        pcode: 'about',
      },
      {
        title: '组织机构',
        type: 'inner',
        code: 't2',
        url: '/org-detail/1248622269035446274',
        pcode: 'about',
      },
      {
        title: '联系我们',
        type: 'inner',
        code: 't3',
        url: '/org-detail/1584374110746312705',
        pcode: 'about',
      },
    ]
  },
  {
    title: '新闻中心',
    icons: 'ico ico3',
    url: '/article/news?name=本站头条',
    code: 'news-center',
    type: 'inner',
    subs: [
      {
        title: '通知公告',
        type: 'inner',
        code: 'notice',
        url: '/article/notice?name=通知公告',
        pcode: 'news-center',
      },
      {
        title: '宁团要讯',
        type: 'inner',
        code: 'important',
        url: '/article/important?name=宁团要讯',
        pcode: 'news-center',
      },
      {
        title: '最新动态',
        type: 'inner',
        code: 'news',
        url: '/article/news?name=最新动态',
        pcode: 'news-center',
      },
      {
        title: '基层动态',
        type: 'inner',
        code: 'base-around',
        url: '/article/groups-around?name=基层动态',
        pcode: 'news-center',
        subs: [
          {
            title: '市县区团组织',
            type: 'inner',
            code: 'groups-around',
            url: '/article/groups-around?name=市县区团组织',
            pcode: 'base-around',
          },
          {
            title: '直属团组织',
            type: 'inner',
            code: 'each-direct-group',
            url: '/article/each-direct-group?name=直属团组织',
            pcode: 'base-around',
          },
          {
            title: '学校团组织',
            type: 'inner',
            code: 'school-group',
            url: '/article/school-group?name=学校团组织',
            pcode: 'base-around',
          },
          {
            title: '企业团组织',
            type: 'inner',
            code: 'enterprise-group',
            url: '/article/enterprise-group?name=企业团组织',
            pcode: 'base-around',
          },
        ]
      },
    ]
  },
  {
    title: '团青互动',
    icons: 'ico ico3',
    url: '#',
    type: 'inner',
    code: 'youth-interact',
    subs: [
      {
        title: '智慧团建',
        type: 'out',
        code: 't2',
        url: 'https://zhtj.youth.cn/zhtj/signin',
        pcode: 'youth-interact',
      },
      {
        title: '青年来信',
        type: 'inner',
        code: 'youth-letter',
        url: '/detail/1580754388369223681',
        pcode: 'youth-interact',
      },
      {
        title: '综合服务平台',
        type: 'out',
        code: 't1',
        url: 'https://member.nxgqt.org/#/register',
        pcode: 'youth-interact',
      },
      {
        title: '青年热线',
        type: 'inner',
        code: 't3',
        url: '/detail/1584712811213398017',
        pcode: 'youth-interact',
      },
      {
        title: '宁夏中长期青年发展规划',
        type: 'inner',
        code: 't4',
        url: '/org-news/youth?name=宁夏中长期青年发展规划',
        pcode: 'youth-interact',
      },
      {
        title: '青年大学习',
        type: 'inner',
        code: 't5',
        url: '/org-news/study?name=青年大学习',
        pcode: 'youth-interact',
      },
    ]
  },
  {
    title: '团属组织',
    icons: 'ico ico5',
    url: '/article/volunteer-association?name=宁夏志愿者协会',
    type: 'inner',
    code: 'group',
    subs: [
      // {
      //   code: 'young-entrepreneurs',
      //   type:'inner',
      //   title: '宁夏青年企业家协会',
      //   url: '/article/young-entrepreneurs?name=宁夏青年企业家协会',
      //   pcode: 'group',
      // },
      {
        code: 'youth-federation',
        title: '宁夏青年联合会',
        url: '/article/youth-federation?name=宁夏青年联合会',
        type: 'inner',
        pcode: 'group',
      },
      {
        code: 'student-federation',
        title: '宁夏学生联合会',
        url: '/article/student-federation?name=宁夏学生联合会',
        type: 'inner',
        pcode: 'group',
      },
      {
        code: 'young-pioneers',
        title: '宁夏少年先锋队',
        url: '/article/young-pioneers?name=宁夏少年先锋队',
        type: 'inner',
        pcode: 'group',
      },
      {
        code: 'volunteer-association',
        type:'inner',
        title: '宁夏志愿者协会',
        url: '/article/volunteer-association?name=宁夏志愿者协会',
        pcode: 'group',
      },
      {
        code: 'youth-foundation',
        type:'out',
        title: '宁夏青少年发展基金会',
        url: 'http://www.nxydf.org.cn/#/home',
        pcode: 'group',
      },
      {
        code: 'youth-league',
        type:'inner',
        title: '宁夏青年创业就业基金会',
        url: '/article/youth-league?name=宁夏青年创业就业基金会',
        pcode: 'group',
      },
    ]
  },
  {
    title: '资料下载',
    icons: 'ico ico5',
    url: '/article/youthWiki?name=团务百科',
    type: 'inner',
    code: 'doc-center',
    subs: [
      {
        title: '团务百科',
        type: 'inner',
        code: 'youthWiki',
        url: '/article/youthWiki?name=团务百科',
        pcode: 'doc-center',
        // subs: [
        //   {
        //     title: '基本情况',
        //     type: 'inner',
        //     code: 'wiki-base',
        //     url: '/article/wiki-base?name=基本情况',
        //   },
        //   {
        //     title: '工作动态',
        //     type: 'inner',
        //     code: 'wiki-work',
        //     url: '/article/wiki-work?name=工作动态',
        //   },
        // ]
      },
      {
        title: '队务知识',
        type: 'inner',
        code: 'pioneerKnowledge',
        url: '/article/pioneerKnowledge?name=队务知识',
        pcode: 'doc-center',
        // subs: [
        //   {
        //     title: '基本情况',
        //     type: 'inner',
        //     code: 'pioneer-base',
        //     url: '/article/pioneer-base?name=基本情况',
        //   },
        //   {
        //     title: '工作动态',
        //     type: 'inner',
        //     code: 'pioneer-work',
        //     url: '/article/pioneer-work?name=工作动态',
        //   },
        // ]
      },
      {
        title: '文化产品',
        type: 'inner',
        code: 'video-docs',
        url: '/article/video-docs?name=视频资料',
        pcode: 'doc-center',
      },
      // {
      //   title: '宁夏中长期青年发展规划',
      //   type: 'inner',
      //   code: 'youth',
      //   url: '/org-news/youth?name=宁夏中长期青年发展规划',
      //   pcode: 'doc-center',
      // },
    ]
  },
  {
    title: '青年企业家协会',
    icons: 'ico ico3',
    url: '#',
    type: 'inner',
    code: 'young-entrepreneurs',
    hide: true,
    subs: [
      {
        title: '基本信息',
        type: 'inner',
        code: 'youth-ent-base',
        url: '/article/youth-ent-base?name=基本信息',
        pcode: 'young-entrepreneurs',
      },
      {
        title: '工作动态',
        type: 'inner',
        code: 'youth-ent-work',
        url: '/article/youth-ent-work?name=工作动态',
        pcode: 'young-entrepreneurs',
      },
    ]
  }
]


export const homeCat1: Category[] = [
  {
    code: 'hot',
    name: '本站头条',
  },
  {
    code: 'news',
    name: '最新动态',
  },
  {
    code: 'notice',
    name: '通知公告',
  },
  {
    code: 'important',
    name: '宁团要讯',
  },
  // {
  //   code: 'docs',
  //   name: '文件资料',
  // },
]

export const homeCat2: Category[] = [
  {
    code: 'groups-around',
    name: '市县区团组织',
  },
  {
    code: 'each-direct-group',
    name: '直属团组织',
  },
  {
    code: 'school-group',
    name: '学校团组织',
  },
  {
    code: 'enterprise-group',
    name: '企业团组织',
  },
]

export const allCats: Category[] = [...homeCat1, ...homeCat2]

export const topics: TopicModel[] = [
  {
    image: topImg1,
    linkUrl: '#',
    klass: 'lk1',
  }, {
    image: topImg2,
    linkUrl: '#',
    klass: 'lk2',
  }, {
    image: topImg3,
    linkUrl: '#',
    klass: 'lk2',
  },
]

export const wechats: Wechat[] = [
  {
    id: '1',
    image: wechatImg1,
    title: '宁夏共青团',
  },
  {
    id: '2',
    image: wechatImg2,
    title: '青春宁夏',
  },
  {
    id: '3',
    image: wechatImg3,
    title: '网络平台',
  },
  {
    id: '4',
    image: wechatImg1,
    title: '青年来信',
  },
]

export const findNavByCode = (code: string): Nav[] => {
  console.log("===> code:", code, navs)
  let top: Nav
  let sub: Nav
  let third: Nav
  for (let i = 0; i < navs.length; i++) {
    const subs = navs[i].subs || []
    top = navs[i]

    if (navs[i].code === code) {
      return [top]
    }

    for (let j = 0; j < subs?.length; j++) {
      const _subs = subs[j].subs || []
      sub = subs[j]


      if (sub.code === code) {
        return [top, sub]
      }

      for (let k = 0; k < _subs?.length; k++) {
        third = _subs[k]

        if (third.code === code) {
          return [top, sub, third]
        }
      }
    }
  }

  return []
}

export const findSubNavByCode = (topCode: string, subCode: string) => {
  const top = navs.find(it => it.code === topCode);
  if (top) {
    return top.subs?.find(sub => sub.code === subCode)
  }

  return {}
}
