import React, { useEffect, useState } from 'react'
import { useWindowSize } from '@react-hook/window-size'

import { wechats } from '../pages/data'

interface Props {
  children?: React.ReactNode
}

const FloatPanel: React.FC<Props> = () => {
  const [curr, setCurr] = useState<number>(0)
  const [left, setLeft] = useState<number>(0)
  const [wx] = useState<number>(100)
  const [width, _] = useWindowSize()


  useEffect(() => {
    const pad = 5;
    const _left = (width - 1200) / 2
    setLeft(_left - wx > pad ? _left - wx - pad : 0)
  }, [width])

  return (
    <div className="side-ewm tabmenu" style={{ width: wx, left: left }}>
      {wechats.map((it, index) => {
        return <div key={index} onMouseMove={() => setCurr(index)} className='item lk cur'>
          <p className="fz">{it.title}</p>
          <img src={it.image} className="pic" alt="" />
        </div>
      })}
    </div>
  )
}

export default FloatPanel
