import { Outlet } from "react-router-dom";
import FloatPanel from "./components/floatPanel";
import Footer from "./components/footer";
import Header from "./components/header";

import "./assets/css/style.css";

function App() {
  document.body.classList.add("body");

  return (
    <div className="App">
      <Header />
      <Outlet />
      <Footer />
      <FloatPanel />
    </div>
  );
}

export default App;
