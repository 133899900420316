import React from 'react'
import { Category } from '../pages/data'
// import img1 from '../assets/images/nxlogo/1.png'
import img2 from '../assets/images/nxlogo/2.png'
import img3 from '../assets/images/nxlogo/3.png'
import img4 from '../assets/images/nxlogo/4.png'
import img5 from '../assets/images/nxlogo/nx_qinlian.png'
import img6 from '../assets/images/nxlogo/8.png'
import img7 from '../assets/images/nxlogo/7.png'
import { Link } from 'react-router-dom'

import Marquee from 'react-fast-marquee'

interface Props {
  children?: React.ReactNode
}

const Marqueex: React.FC<Props> = () => {
  const cats: Category[] = [
    /* {
     *   id: '1',
     *   code: 'young-entrepreneurs',
     *   name: '宁夏青年企业家协会',
     *   image: img1,
     *   url: '/article',
     * }, */
    {
      id: '5',
      code: 'youth-federation',
      name: '宁夏青年联合会',
      image: img5,
      url: '/article',
    },
    {
      id: '6',
      code: 'student-federation',
      name: '宁夏学生联合会',
      image: img6,
      url: '/article',
    },
    {
      id: '7',
      code: 'young-pioneers',
      name: '宁夏少年先锋队',
      image: img7,
      url: '/article',
    },
    {
      id: '2',
      code: 'volunteer-association',
      name: '宁夏志愿者协会',
      image: img2,
      url: '/article',
    },
    {
      id: '4',
      code: 'youth-foundation',
      name: '宁夏青少年发展基金会',
      image: img4,
      url: 'http://www.nxydf.org.cn/#/home',
      type: 'out',
    },
    {
      id: '3',
      code: 'youth-league',
      name: '宁夏青年创业就业基金会',
      image: img3,
      url: '/article',
    },
  ]

  return (
    <Marquee speed={50} pauseOnHover gradient={false} style={{ backgroundColor: '#fff' }}>
      {cats.map((it, index) => {
        if (it.type === 'out') {
          return <a key={index} href={it.url} target="_blank" rel="noreferrer">
            <img src={it.image} className="mq-img" alt="" />
          </a>
        } else {
          return <Link key={index} to={`${it.url}/${it.code}?name=${it.name}`}>
            <img src={it.image} className="mq-img" alt="" />
          </Link>
        }
      })}
    </Marquee>
  )
}

export default Marqueex
