import { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams, useNavigate, redirect } from 'react-router-dom'

import { listByCode } from '../api/api'
import { Article, allCats, findNavByCode, Nav } from './data'

import icon1 from '../assets/images/icon_07.png'
import Loading from '../components/loading'

interface Props {
  id: string
}

export interface PageModel {
  current: number,
  pages: number,
  size: number,
  total: number,
  records: Article[]
}

export const getPageNums = (curr: number, pages: number): number[] => {
  if (pages <= 10) {
    return Array.from(Array(pages).keys()).map(x => x)
  } else {
    if (curr <= 5) {
      const arr = []
      for (let i = 0; i < 7; i++) {
        arr.push(i)
      }
      return arr
    } else if (curr >= pages) {
      const [begin, end] = [pages - 7, pages - 1]
      const arr = []
      for (let i = begin; i <= end; i++) {
        arr.push(i)
      }
      return arr
    } else {
      let [begin, end] = [curr - 4, curr + 2]
      if (end > pages - 1) {
        begin = pages - 7
        end = pages - 1
      }
      const arr = []
      for (let i = begin; i <= end; i++) {
        arr.push(i)
      }
      return arr
    }
  }
}

const ArticleList: React.FC<Props> = () => {
  const { code } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [catCode, setCatCode] = useState(code)
  const [currNav, setCurrNav] = useState<Nav[]>([])
  const [params,] = useSearchParams()
  const [list, setList] = useState<Article[]>([])
  const [page, setPage] = useState<PageModel>()
  const [nums, setNums] = useState<number[]>([])
  const [p, setP] = useState<number>(1)
  const [name, setName] = useState<string>(() => params.get('name') || '')
  const navigate = useNavigate()

  const handlePage = (cp: number) => {
    setP(cp)
    loadData(catCode ?? '', cp)
  }

  const handleNext = (cp: number) => {
    if (page) {
      if (cp >= page?.pages) {
        handlePage(page?.pages)
      } else {
        handlePage(cp + 1)
      }
    }
  }

  const handlePrev = (cp: number) => {
    if (cp > 1) {
      handlePage(cp - 1)
    } else {
      handlePage(1)
    }
  }

  const loadData = (code: string, p: number, name?: string) => {
    setIsLoading(true)
    if (code && !name) {
      const _name = allCats.find(it => it.code === code)?.name
      setName(_name ?? '')
    } else {
      setName(name ?? '')
    }

    if (code) {
      listByCode(code, 20, p).then(res => {
        const data = res as PageModel
        setPage(data)
        setList(data.records)
        setNums(getPageNums(data.current, data.pages))
        setIsLoading(false)
      })
    }
  }

  const getTitle = () => {
    if(currNav && currNav?.length > 0) {
      return currNav[0].title
    }
    return '新闻中心'
  }

  const changeCat = (code: string, name: string) => {
    if (code) {
      setP(1)
      setName(name)
      setCatCode(code)
      listByCode(code, 20, p).then(res => {
        const data = res as PageModel
        setPage(data)
        setList(data.records)
        setNums(getPageNums(data.current, data.pages))
      })
    }
  }

  // 判断是否为外部链接
  const dynLink = (article: Article) => {
    if (article.isLink === 1) {
      return <a target="_blank" className="title ellipsis" href={article.linkUrl}>{article.title}</a>
    }

    return <Link to={`/detail/${article.id}`} className="title ellipsis">{article.title}</Link>
  }

  useEffect(() => {
    /* window.scrollTo({ top: 0 }) */
    loadData(catCode as string, p, params.get('name') || '')
  }, [catCode])

  useEffect(() => {
    /* window.scrollTo({ top: 0 }) */
    loadData(code as string, p, params.get('name') || '')

    setCurrNav(findNavByCode(code || ''))
  }, [code])

  return (
    <div className="main auto">
      <div className="top-bar clearfix">
        <span className="bt">{name}</span>
        <div className="crumb fr">
          当前位置: &nbsp;
          <Link to="/">首页 </Link> &gt;
          <span> {name}</span>
        </div>
      </div>
      <div className="wrap clearfix">
        <div className="wrap-l fl">
          <div className="side-menu">
            <h6 className="t-tit">
              <img src={icon1} className="icon" alt="" />
              {getTitle()}
            </h6>
            <ul className="menu-list">
              {currNav.filter((it, index) => index < 1).map(it => {
                return it.subs?.map(sub => {
                  return <li key={sub.code} className={sub.code === code ? 'cur' : ''}>
                    {/* <Link to='' onClick={() => { changeCat(sub?.code || '', sub?.title || '') }}>{sub?.title || ''}</Link> */}
                    <Link to={`/article/${sub?.code}?name=${sub?.title}`} >{sub?.title || ''}</Link>
                    {sub && sub?.subs ?
                      <div className="sub-menu-item">
                        {sub?.subs?.map(third => {
                          return <div key={third.code} className={third.code === code ? 'sub-active' : ''}>
                            <Link to={`/article/${third.code}?name=${third.title}`} className={third.code === code ? 'sub-a-active' : ''}> {third.title} </Link>
                          </div>
                        })}
                      </div>
                      : ''
                    }
                  </li>
                })
              })}
            </ul>
          </div>
        </div>
        <div className="wrap-r">
          {isLoading ?
            <div><Loading /></div>
            :
            <>
              <ul className="xw-list">
                {list.map(it => {
                  return <li key={it.id} className="item">
                    {dynLink(it)}
                    <span className="date">{it.createdAt}</span>
                  </li>
                })}
              </ul>
              <div className="pagelist">
                <span className="total">共{page?.total}条</span>
                <div className="pages">
                  <div className="first" onClick={() => handlePage(1)}>
                    首页
                  </div>
                  <div className="prv" onClick={() => handlePrev(p)}>
                    上一页
                  </div>
                  <ul className="pagingUl">
                    {nums.map(it => {
                      return <li key={it}>
                        <Link onClick={() => handlePage(it + 1)} to='#' className={`${it + 1 === p ? 'active' : ''}`}>{it + 1}</Link>
                      </li>
                    })}
                  </ul>
                  <div className="next" onClick={() => handleNext(p)}>下一页</div>
                  <div className="last" onClick={() => handlePage(page ? page?.pages : 1)}>末页</div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default ArticleList
