import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { listByCode, detail } from '../api/api'
import { Article } from './data'
import icon1 from '../assets/images/icon_07.png'
import img1 from '../assets/images/outlink.png'

interface Props {
  children?: React.ReactNode
  code: string
  title: string
}

const ListDetail: React.FC<Props> = ({ code, title }) => {
  const { xid } = useParams()
  const [list, setList] = useState<Article[]>([])
  const [id, setId] = useState<string>(xid || '')
  const [article, setArticle] = useState<Article>()

  const htmlComp = (html: string) => {
    return { __html: html }
  }

  const content = (article: Article) => {
    if (article.isLink === 1) {
      return <div style={{ textAlign: 'center' }}>
        <div>
          <img src={img1} style={{ width: '40%' }} alt="out link" />
        </div>
        <div>
          <div>
            <span className="out-link-txt">{article.linkUrl}</span>
          </div>
          <a href={article.linkUrl}> <span className="out-link">访问外链</span> </a>
        </div>
      </div>
    } else {
      return <div dangerouslySetInnerHTML={htmlComp(article?.content ?? '')}></div>
    }
  }

  const linkType = (article: Article) => {
    return <Link to='' onClick={() => { setId(article?.id || '') }}>{article?.title}</Link>
  }

  useEffect(() => {
      detail(xid || '').then(res => {
        setArticle(res as Article)
      })
  }, [xid])

  useEffect(() => {
    if (id) {
      detail(id).then(res => {
        setArticle(res as Article)
      })
    }
  }, [id])

  useEffect(() => {
    listByCode(code, 20).then(res => {
      setList(res.records)

      if (res.records && res.records.length > 0) {
        if(!xid) {
          setId(res.records[0].id)
        }
      }
    })
  }, [code])

  return (
    <div className="main auto">
      <div className="top-bar clearfix">
        <span className="bt">{article?.title}</span>
        <div className="crumb fr">
          当前位置: &nbsp;
          <Link to="/">首页 </Link> &gt;
          <span>{title}</span>
        </div>
      </div>
      <div className="wrap clearfix">
        <div className="wrap-l fl">
          <div className="side-menu">
            <h6 className="t-tit">
              <img src={icon1} className="icon" alt="" />{title}
            </h6>
            <ul className="menu-list">
              {list.map(it => {
                return <li key={it.code} className={it.id === id ? 'cur' : ''}>
                  {linkType(it)}
                </li>
              })}
            </ul>
          </div>
        </div>
        <div className="wrap-r">
          <div className='list-detail'>
            {content(article || { title: '' })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListDetail
