import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { detail } from '../api/api'
import { Article } from "./data"
import icon1 from '../assets/images/icon_08.png'
import icon2 from '../assets/images/icon_09.png'

const ArticleDetail: React.FC = () => {
  const { id } = useParams()
  const [article, setArticle] = useState<Article>()

  const htmlComp = (html: string) => {
    return { __html: html }
  }

  useEffect(() => {
    if (id) {
      detail(id).then(res => {
        if (res) {
          setArticle(res as Article)
          window.scrollTo({ top: 0 })
        }
      })
    }
  }, [id])

  return (
    <div className="main auto">
      <div className="top-bar clearfix">
        <span className="bt">新闻详情</span>
        <div className="crumb fr">
          当前位置:
          <Link to="/">首页 </Link> &gt;
          <Link to={`/article/${article?.category?.code}?name=${article?.category?.name}`}> {article?.category?.name}</Link> &gt;
          <span> 新闻详情 </span>
        </div>
      </div>
      <div className="det-sec">
        <h5 className="title">{article?.title}</h5>
        <div className="sm">
          <span>{article?.source}</span>
          <span><img src={icon2} className="ico" alt="" />{article?.createdAt}</span>
          <span><img src={icon1} className="ico" alt="" />{article?.visits}</span>
        </div>
        <div className="editbox">
          <div dangerouslySetInnerHTML={htmlComp(article?.content ?? '')}></div>
        </div>
      </div>
    </div>
  )
}

export default ArticleDetail
