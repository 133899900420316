import { request } from './request'

const flashList = (code: string) => {
  return request.get<any>('/api/cms/flash/list', { code: code })
}

const search = (query: string, p: number = 1) => {
  return request.get<any>('/api/cms/article/findList', { search: `title:${query}`, page: p })
}

const listByCode = (code: string, ps: number = 10, p: number = 1) => {
  return request.get<any>('/api/cms/article/findList', { code: code, pageSize: ps, page: p })
}

const topCatArticle = (code: string) => {
  return request.get<any>(`/api/cms/article/cat-top/${code}`)
}

const detail = (id: string) => {
  return request.get(`/api/cms/article/show/${id}`)
}

// 获取网站主题
const getTopics = () => {
  return request.get(`/api/cms/topic/list`)
}

// 获取专题
const getSpecial = () => {
  return request.get(`/api/cms/special/list-new`)
}

// 获取专题文章
const getSpecArticle = (id: string) => {
  return request.get(`/api/cms/special/articles/${id}`)
}

// 友情链接
const getFriendLinks = (linkType: string) => {
  return request.get(`/api/cms/friend-link/list?type=${linkType}`)
}

// 测试异步调用
const test = async () => {
  return request.get('/api/cms/special/list')
}

export {
  test,
  search,
  flashList,
  listByCode,
  detail,
  getTopics,
  getSpecial,
  getSpecArticle,
  getFriendLinks,
  topCatArticle,
}
