import {
  createBrowserRouter,
} from "react-router-dom";

import App from './App';
import Home from './pages/home'
import ArticleList from './pages/articles';
import ArticleDetail from './pages/detail';
import Topic from './pages/topics';
import Test from "./pages/test";
import NoCatList from "./pages/noCatList";
import ListDetail from "./pages/listDetail";

const router = createBrowserRouter([
    {
      path: "/",
      element: <App/>,
      children: [
        {
          path: "/",
          element: <Home/>,
        },
        {
          path: "article/:code",
          element: <ArticleList id={'1'}/>,
        },
        {
          path: "detail/:id",
          element: <ArticleDetail />,
        },
        {
          path: "search/:query",
          element: <NoCatList code='query' title='全站搜索' />,
        },
        {
          path: "docs",
          element: <NoCatList code='doc' title='资料中心' />,
        },
        {
          path: "notice",
          element: <NoCatList code='notice' title='资料中心' />,
        },
        {
          path: "org-news/:catCode",
          element: <NoCatList code='' title='' />,
        },
        {
          path: "tqhd-list-detail",
          element: <ListDetail code="interact" title='团青互动' />,
        },
        {
          path: "orgs",
          /* element: <NoCatList code='org' title='关于我们' />, */
          element: <ListDetail code="org" title='关于我们' />,
        },
        {
          path: "org-detail/:xid",
          /* element: <NoCatList code='org' title='关于我们' />, */
          element: <ListDetail code="org" title='关于我们' />,
        },
        {
          path: "test",
          element: <Test />,
        },
        {
          path: "topics",
          element: <Topic />,
        }
      ]
    },
]);

export default router
