import React from 'react'
import { fullUrl } from '../utils/common'
import noImage from '../assets/images/no_image.png'

interface Props {
  children?: React.ReactNode
  href?: string | null | undefined
  empty?: string | undefined
}

const ZImage: React.FC<Props> = ({ href, empty }) => {
  if (href) {
    return <img src={fullUrl(href)} className="img" alt="" />
  }

  return <img src={empty ? empty : noImage} className="img" style={{ border: '1px solid #ddd' }} alt="" />
}

export default ZImage
