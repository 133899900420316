import React, { useEffect, useState } from 'react'

interface Props {
  children?: React.ReactNode
}

const Test: React.FC<Props> = () => {
  const [count, setCount] = useState(0)
  const [dely, setDely] = useState(100)

  // 处理定时器
  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1)
    }, dely)

    return () => { clearInterval(interval) }
  }, [count, dely])

  return (
    <div style={{ textAlign: 'center' }}>
      @@ {count} @@
      <button onClick={() => setDely(dely - 10)}> &lt; </button>
      {dely}
      <button onClick={() => setDely(dely + 10)}> &gt; </button>
    </div>
  )
}

export default Test
