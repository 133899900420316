import HomeBlock1 from "../components/homeBlock1";
import HomeBlock2 from "../components/homeBlock2";
import HomeBlock3 from "../components/homeBlock3";
import Marqueex from "../components/marquee";
import { Link } from "react-router-dom"
import Topic from "../components/topic";

const Home: React.FC = () => {
  return (
    <div className="idx-main">
      <div className="auto">
        <Topic />
        <HomeBlock1 />
        <Marqueex />
        <Link to='/org-news/youth?name=宁夏中长期青年发展规划'>
          <div className="youth-banner">
            宁夏中长期青年发展规划2019-2025年
          </div>
        </Link>
        <HomeBlock2 />
        <HomeBlock3 />
      </div>
    </div>
  );
};

export default Home;
