import React, { useEffect, useState } from 'react'
import { Article } from './data'
import { listByCode, getSpecial, getSpecArticle } from '../api/api'

import noImage from '../assets/images/no_image1.png'
import icon1 from '../assets/images/icon_04.png'
import { Link } from 'react-router-dom'


interface Props {
  children?: React.ReactNode
  article?: Article
}

interface Special {
  id?: string
  title?: string
  backgroundImage?: string
  isShow?: number
}

const Card: React.FC<Props> = ({ article }) => {
  const loadImg = (img: string | undefined): string => {
    if (!img) {
      return noImage
    }
    if (img.startsWith('http')) {
      return img
    } else {
      return `https://api.nxgqt.org${img}`
    }
  }
  return (
    <li className="item">
      <Link to={`/detail/${article?.id}`} className="imgbox">
        <img src={loadImg(article ? article?.coverImage : '')} className="img" alt="" />
      </Link>
      <div className="info">
        <h5><Link to={`/detail/${article?.id}`} className="title ellipsis">{article?.title}</Link></h5>
        <div className="txt clamp">{article?.intro}</div>
        <Link to={`/detail/${article?.id}`} className="more">查看</Link>
      </div>
    </li>
  )
}

const Topic: React.FC<Props> = () => {
  const [curr, setCurr] = useState<number>(0)
  const [articles, setArticles] = useState<Article[]>([])
  const [specials, setSpecials] = useState<Special[]>([])

  // 加载专题文章图片
  const loadArticle = async (code: string, index: number) => {
    const result = await getSpecArticle(code) as Article[];
    setCurr(index)
    setArticles(result)
  }

  // 加载专题列表
  const loadSpecils = async () => {
    const result = await getSpecial() as Special[]
    setSpecials([{ id: 'all', title: '所有文章' }, ...result])

    // 加载默认分类
    loadArticle('all', 0)
  }

  const parseTitle = (title: string | undefined): string => {
    if (title) {
      if (title.length > 10) {
        return `${title.substring(0, 10)}...`
      }
      return title
    }

    return ''
  }

  useEffect(() => {
    loadSpecils()
  }, [])

  return (
    <div className="main auto">
      <div className="">
        <div className="top-bar clearfix">
          <span className="bt">专题列表</span>
          <div className="crumb fr">
            当前位置: <a href="/">首页</a> &gt; <span>专题列表</span>
          </div>
        </div>
        <div className="wrap clearfix">
          <div className="wrap-l fl">
            <div className="side-menu">
              <h6 className="t-tit">
                <img src={icon1} className="icon" alt="" />专题列表
              </h6>
              <ul className="menu-list">
                {specials.map((it, index) => {
                  return <li className={`${curr === index ? 'cur' : ''}`} key={it.id}>
                    <Link onClick={() => { loadArticle(it?.id ?? '', index) }} to="#" title={it.title}>{parseTitle(it?.title)}</Link>
                  </li>
                })}
              </ul>
            </div>
          </div>
          <div className="wrap-r">
            {articles.length > 0 ? (
              <ul className="zt-list clearfix">
                {articles.map(it => {
                  return <Card key={it.id} article={it} />
                })}
              </ul>
            ) : (
              <div className="no-list-data">
                <div>
                  <img src={noImage} />
                </div>
                <div>
                  <span>暂无数据</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Topic
