import React, { useEffect, useState } from 'react'
import { FriendLink } from '../pages/data'
import { getFriendLinks } from '../api/api'

interface Props {
  children?: React.ReactNode
  title?: string
  links?: FriendLink[]
}

const FriendLinkItem: React.FC<Props> = ({ title, links }) => {
  const handleGoLink = (ev: any) => {
    console.log('link', ev.target.value)
    const url = ev.target.value;
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  }
  return (
    <li className="item">
      <select name="" id="" className="select" onChange={handleGoLink}>
        <option value="">{title}</option>
        {links?.map((it, index) => {
          return <option key={index} value={it.siteLink}>{it.siteName}</option>
        })}
      </select>
    </li>
  )
}

const Footer: React.FC<Props> = ({ children }) => {
  const [fl1, setFl1] = useState<FriendLink[]>([]);
  const [fl2, setFl2] = useState<FriendLink[]>([]);
  const [fl3, setFl3] = useState<FriendLink[]>([]);
  const [fl4, setFl4] = useState<FriendLink[]>([]);

  useEffect(()=>{
    getFriendLinks('center').then(res=> {
      setFl1(res as FriendLink[])
    })
    getFriendLinks('other').then(res=> {
      setFl2(res as FriendLink[])
    })
    getFriendLinks('province').then(res=> {
      setFl3(res as FriendLink[])
    })
    getFriendLinks('city').then(res=> {
      setFl4(res as FriendLink[])
    })
  }, [])

  return (
    <div className="foot">
      <div className="auto">
        <h6 className="t-tit">友情链接</h6>
        <ul className="list clearfix">
          <FriendLinkItem title='团中央、直属单位' links={fl1} />
          <FriendLinkItem title='其他单位（政府部门）' links={fl2} />
          <FriendLinkItem title='省级团委' links={fl3} />
          <FriendLinkItem title='各地市及高校' links={fl4} />
        </ul>
        <div className="txt">
          <p>
            <span>
              ICP备案号：<a href='https://beian.miit.gov.cn/'>宁ICP备05001641号-3</a>
            </span> /
            <span>版权所有:共青团宁夏回族自治区委员会</span> /
            <span>地址: 宁夏银川市金凤区正源北街135号</span>
          </p>
          <p>
            <span>技术支持: <a href='https://nxzzld.com'>宁夏中正联达信息科技有限公司</a></span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
