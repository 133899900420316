import React, { useEffect, useState } from 'react'
import { homeCat2, Article, Category } from '../pages/data'
import { listByCode, topCatArticle } from '../api/api'
import noImage from '../assets/images/no_image.png'

import Articles from './common'
import { Link } from 'react-router-dom'
import ZImage from './ZImage'

interface Props {
  children?: React.ReactNode
}

interface PanelProps {
  klass: string
  top?: Article[]
  articles?: Article[]
}

const SubPanel: React.FC<PanelProps> = ({ klass, top, articles }) => {
  return (
    <div className={`col ${klass}`}>
      <div className="idx-mod">
        <Articles articles={articles} />
      </div>
    </div>
  )
}

const HomeBlock2: React.FC<Props> = () => {
  const [currCat, setCurrCat] = useState<number>(0)
  const [cat, setCat] = useState<Category>()
  const [tops, setTops] = useState<Article[]>([])
  const [regionLeft, setRegionLeft] = useState<Article[]>([])
  const [regionRight, setRegionRight] = useState<Article[]>([])

  const changeRegion = (index: number, code: string) => {
    setCurrCat(index)
    setCat(homeCat2.find(it => it.code === code))
    topCatArticle(code).then(res => {
      const list = res as Article[]
      setTops(list)
    })

    listByCode(code, 20).then(res => {
      const list = res.records as Article[]
      const left = list.filter((_, idx) => idx < 10)
      const right = list.filter((_, idx) => idx > 9)
      setRegionLeft(left)
      setRegionRight(right)
    })
  }

  useEffect(() => {
    changeRegion(0, 'groups-around')
  }, [])

  return (
    <div className="idx-s3 m" style={{ paddingBottom: 30 }}>
      <div className="idx-menu tabmenu">
        <div className="box">
          {homeCat2.map((it, index) => {
            return <span key={index} onMouseOver={() => changeRegion(index, it.code)} onClick={() => changeRegion(index, it.code)} className={`lk ${currCat === index ? 'cur' : ''} `}>{it.name}</span>
          })}
        </div>
      </div>
      <div className="tabwrap">
        <div className="hot-pic all-line-pic">
          {tops.map(it => {
            return <Link to={`/detail/${it.id}`} className="imgbox">
              <ZImage href={it.coverImage} />
              <h5><Link to={`/detail/${it.id}`} className="title ellipsis">{it.title}</Link></h5>
            </Link>
          })}
        </div>
        <div className="module clearfix">
          <SubPanel klass='fl' articles={regionLeft} />
          <SubPanel klass='fr' articles={regionRight} />
        </div>
        <div className="more mg-b-10">
          <Link to={`/article/${cat?.code}?name=${cat?.name}`}>
            更多 &gt;&gt;
          </Link>
          <div>&nbsp;</div>
        </div>
      </div>
    </div>
  )
}

export default HomeBlock2
